























































































































































import {
  Component, Inject, InjectReactive,
  ProvideReactive,
  Vue, Watch,
} from 'vue-property-decorator';
import { ApiFacade } from '@/services/ApiFacade';
import { Polygon } from '@/models/entities/Polygon';
import { Camera } from '@/models/entities/Camera';
import { CameraStatus } from '@/types/Statuses';
import cloneDeep from 'lodash/cloneDeep';
import { Context } from 'vuex-smart-module';
import { AppModule } from '@/store/app/AppModule';
import { Cell } from '@/models/entities/Cell';
import { NotificationsService } from '@/services/NotificationService';

@Component({})
export default class PolygonCamsView extends Vue {
  @Inject() appStore!: Context<AppModule>;
  @Inject() readonly apiFacade!: ApiFacade;
  @Inject() notify!: NotificationsService;
  @InjectReactive() polygon!: Polygon;
  private isLoading = false;
  @ProvideReactive() private cameras: Camera[] = [];
  private editedIndex = -1;
  private editedItem: Camera = new Camera();
  private dialog = false;
  private dialogDelete = false;

  private headers = [
    {
      text: 'Имя камеры',
      align: 'start',
      value: 'cameraName',
    },
    {
      text: 'Описание',
      align: 'start',
      value: 'description',
    },
    {
      text: 'ID видео-потока',
      align: 'start',
      value: 'cameraVideoId',
    },
    {
      text: 'Камера',
      align: 'center',
      value: 'videoCamera',
    },
    {
      text: 'Номер ячейки',
      align: 'center',
      value: 'cellSequentialNumber',
    },
    {
      text: 'Статус',
      align: 'start',
      value: 'status',
    },
    {
      text: 'Действия',
      value: 'actions',
      sortable: false,
    },
  ];

  @Watch('dialog')
  dialogWatcher(val: boolean) {
    if (!val) {
      this.close();
    }
  }

  @Watch('dialogDelete')
  dialogDeleteWatcher(val: boolean) {
    if (!val) {
      this.closeDelete();
    }
  }

  get editedItemCellId(): CellFeatureIriIdentifier {
    return this.editedItem.cell?.['@id'] || '';
  }

  set editedItemCellId(iriId: string) {
    if (!this.editedItem) {
      return;
    }
    const cell = this.polygonCells.find((c) => c['@id'] === iriId);
    this.editedItem.cell = cell || null;
  }

  get polygonCells(): Cell[] {
    return this.polygon.cells || [];
  }

  get polygonCellsWithEmpty(): Pick<Cell, '@id' | 'displayName'>[] {
    const cells = this.polygonCells.map((c) => {
      return {
        '@id': c['@id'],
        displayName: c.displayName,
      };
    });
    cells.unshift({
      '@id': '',
      displayName: 'Нет',
    });
    return cells;
  }

  get isSuperAdmin() {
    return this.appStore.getters.profile?.isSuperAdmin;
  }

  get cameraStatuses(): CameraStatus[] {
    return [CameraStatus.active, CameraStatus.inactive];
  }

  get formTitle() {
    return this.editedIndex === -1 ? 'Новая камера' : 'Редактирование камеры';
  }

  async mounted() {
    await this.loadPolygonCameras();
  }

  @Watch('polygon')
  async loadPolygonCameras() {
    try {
      this.isLoading = true;
      if (this.polygon) {
        this.cameras = await this.apiFacade.fetchPolygonCameras(this.polygon);
      }
    } catch (e) {
      console.error('Fetch cameras error', e);
    } finally {
      this.isLoading = false;
    }
  }

  editItem(item: Camera) {
    this.editedIndex = this.cameras.indexOf(item);
    this.editedItem = cloneDeep(item);
    this.dialog = true;
  }

  deleteItem(item: Camera) {
    this.editedIndex = this.cameras.indexOf(item);
    this.editedItem = item;
    this.dialogDelete = true;
  }

  async deleteItemConfirm() {
    await this.apiFacade.deleteCamera(this.editedItem);
    this.cameras.splice(this.editedIndex, 1);
    this.closeDelete();
    await this.notify.addNotification({ type: 'success', text: 'Камера удалена' });
  }

  close() {
    this.dialog = false;
    this.$nextTick(() => {
      this.editedItem = new Camera();
      this.editedIndex = -1;
    });
  }

  closeDelete() {
    this.dialogDelete = false;
    this.$nextTick(() => {
      this.editedItem = new Camera();
      this.editedIndex = -1;
    });
  }

  async save() {
    const clonedCam = cloneDeep(this.editedItem);
    clonedCam.polygon = this.polygon['@id'];

    try {
      if (this.editedIndex > -1) {
        const apiCam = await this.apiFacade.updateCamera(clonedCam);
        Object.assign(this.cameras[this.editedIndex], apiCam);
        await this.notify.addNotification({ type: 'success', text: 'Камера успешно обновлена' });
      } else if (this.editedItem) {
        const apiCam = await this.apiFacade.createCamera(clonedCam);
        this.cameras.push(apiCam);
        await this.notify.addNotification({ type: 'success', text: 'Камера успешно создана' });
      }
      this.close();
    } catch (e) {
      await this.notify.addNotification({ type: 'error', text: 'Ошибка!' });
    }
  }
}
